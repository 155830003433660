@use "sass:math";@import "~@/common/styles/variables.scss";
.el-message-box__title {
  text-align: center;
}

.seawave {
  margin-top: -150px;
  position: relative;
  z-index: 10;
}

.clear-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.index-container {
  .home-top {

    // > img {
    //   width: 100%;
    // }
    .swiper-out-wrapper {
      max-height: 600px;

      .swiper-slide>img {
        width: 100%;
        object-fit: cover;
        max-height: 600px;
      }
    }
  }

  .home-content {
    .home-menu {
      width: 100%;

      .menu-list {
        margin: auto;
        width: 90%;

        .menu-item {
          text-align: center;
          margin-top: 20px;

          >img {
            height: 180px;
            width: 180px;
          }

          .title {
            font-size: 20px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.home-section {
  padding-top: 30px;

  .home-show,
  .home-item {
    width: 90%;
    margin: 0 auto;

    .home-show-right {
      margin-top: 15px;
    }

    .yc-wrapper {
      padding: 20px 0px;
    }

    .des {
      text-align: left;

      .des-txt {
        line-height: 1.5;
        white-space: pre-line;
      }
    }
  }
}

.home-ep {
  padding-bottom: 30px;

  .swiper-out-wrapper {
    padding: 20px 0px;
  }
}

.home-bg {
  background-color: $base-color-background;
}
