$base-color-background: #F4F5F4;
$base-color-white: #fff;
$base-color-red:#CA3935;
$base-color-font:#666666;
//默认动画
$base-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),border 0s,color 0.1s, font-size 0s;
$base-transition-all:all 0.5s ease;
//默认动画长
$base-transition-time: 0.3s;
//边框颜色
$base-border-color: #dcdfe6;
//输入框高度
$base-input-height: 32px;
//默认margin
$base-margin: 20px;
//默认padding
$base-padding: 20px;

//顶部nav-bar的高度
$base-nav-height: 70px;
//app-main的高度
$base-keep-alive-height: calc(
  100vh - #{$base-nav-height} - 236px
);
//菜单背景色
$base-nav-background: #fff;
//基础字体
$font20:1.42rem;
$font30:2.14rem;
$font35:2.5rem;
$font15:1.07rem;
$font10:0.71rem;
$px60:4.28rem;
$px37:2.64rem;
$px50:3.57rem;