@charset "UTF-8";
html body {
  position: relative;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
  font-size: 14px;
  color: #515a6e;
  font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
  box-sizing: border-box;
  background: #F4F5F4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html body * {
  box-sizing: border-box;
  outline: none !important;
}
html body *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
html body *::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border: 3px solid transparent;
  border-radius: 7px;
}
html body *::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
html body #app {
  height: 100vh;
  overflow: auto;
}
html body #app::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
html body #app::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  border: 3px solid transparent;
  border-radius: 7px;
}
html body #app::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
html body #app .site-main {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
}
html body #app .site-main .site-app-main {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
}
html body #app .site-main .site-app-main > section {
  background: #fff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
}
html body #app .site-main .site-app-main > section > [class*=-container] {
  min-height: calc(100vh - 70px - 236px);
  background: #fff;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
}

.detail-container {
  background: #F4F5F4 !important;
}
.detail-container .detail-wrapper {
  max-width: 1200px;
  margin: 10px auto;
  margin-bottom: 3.57rem;
  text-align: left;
  padding: 15px;
}
.detail-container .detail-wrapper .detail-tip {
  margin-bottom: 20px;
  text-align: center;
}
.detail-container .detail-wrapper img.detail-img {
  width: 100%;
  max-width: 400px;
  display: block;
}
.detail-container .detail-wrapper .detail-item .detail-title {
  font-size: 20px;
  margin-top: 20px;
}
.detail-container .detail-wrapper .detail-item .detail-abs {
  margin-top: 10px;
  font-size: 14px;
}

.agenda-nodata {
  font-size: 1.42rem;
  color: #fff;
  margin-top: 20px;
}

.agenda-section {
  background: linear-gradient(90deg, #ca3935 0%, #bb08ab 100%);
  min-height: 300px;
  margin-top: 30px;
  padding-bottom: 30px;
}
.agenda-section .center {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  background: #F4F5F4 !important;
}
.content-container .content-wrapper {
  max-width: 1200px;
  margin: 10px auto;
  padding: 15px;
  margin-bottom: 3.57rem;
}

.swiper-out-wrapper {
  overflow: hidden;
}

.more {
  text-align: right;
  color: #CA3935;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.more .tool {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 10px;
}
.more .tool img {
  margin-left: 4px;
  width: 10px;
  height: 14px;
}

.pdt20 {
  padding-top: 20px;
}

.pdr20 {
  padding-right: 20px;
}

/* 文字显示 */
.text-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.txt-one-line,
.txt-two-line,
.txt-three-line,
.txt-four-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.txt-one-line {
  -webkit-line-clamp: 1;
}

.txt-two-line {
  -webkit-line-clamp: 2;
}

.txt-three-line {
  -webkit-line-clamp: 3;
}

.txt-four-line {
  -webkit-line-clamp: 4;
}

/**图片特效**/
.img-hover {
  position: relative;
}
.img-hover:hover::after {
  opacity: 1;
}
.img-hover::after {
  content: "";
  opacity: 0;
  transition: all 800ms ease;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  background-image: url("https://iscfiles.oss-cn-shanghai.aliyuncs.com/tmp/ETE/web/hover.png");
  background-repeat: no-repeat;
  background-size: 50px 50px;
  background-position: 50% 50%;
  border-radius: 8px 8px 0 0;
  color: #CA3935;
}