@use "sass:math";@import "~@/common/styles/variables.scss";
.file-list {
  margin: 20px auto;

  .file-item-wrapper {
    // border: 1px solid #000;
    padding: 10px;

    .file-item {
      background: #fff;
      padding: 15px 20px;
      text-align: left;

      // margin-right: $font30;
      // margin-top: $font30;
      >img {
        width: $px37;
        vertical-align: bottom;
      }

      .txt {
        margin-left: $font20;

        a {
          font-size: $font20;
          color: $base-color-font;
        }
      }
    }
  }
}
